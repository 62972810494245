var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"space-y-10"},[(_vm.maintenanceShow || _vm.maintenanceActive)?_c('div',{staticClass:"mx-auto w-full md:w-3/4"},[_c('alert-maintenance',{attrs:{"text":_vm.maintenanceText}})],1):_vm._e(),(!_vm.maintenanceActive)?_c('div',{staticClass:"space-y-10"},[_c('div',{staticClass:"mx-auto w-full sm:w-3/5 lg:w-1/2 xl:w-2/5"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"space-y-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('form-input',{attrs:{"id":"publicRgnNr","rules":{
                            required: true,
                            regex: /^\d{3,6}\/?\d{6}$/,
                        },"label":_vm.$t('labels.rgnNr'),"validation-mode":"lazy"},scopedSlots:_vm._u([{key:"help",fn:function(){return [_c('modal-help',{staticClass:"-mt-1",attrs:{"type":"rgnNr","title":_vm.$t(
                                        'components.formAuthLogin.help.rgnNr.title'
                                    ),"button":_vm.$t(
                                        'components.formAuthLogin.help.rgnNr.button'
                                    ),"alt":_vm.$t('labels.rgnNr')}})]},proxy:true}],null,true)},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.publicRgnNr),expression:"publicRgnNr",modifiers:{"trim":true}}],staticClass:"form-input w-full border-none sm:text-sm sm:leading-5",attrs:{"id":"publicRgnNr","placeholder":_vm.$t('components.formAuthLogin.pattern.rgnNr'),"type":"text"},domProps:{"value":(_vm.publicRgnNr)},on:{"input":function($event){if($event.target.composing)return;_vm.publicRgnNr=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('form-input',{attrs:{"id":"onlineCode","rules":{
                            required: true,
                            regex: /^(?:(?![loIO10])[a-zA-Z\d]){6}$/,
                        },"label":_vm.$t('labels.onlineCode'),"validation-mode":"lazy"},scopedSlots:_vm._u([{key:"help",fn:function(){return [_c('modal-help',{staticClass:"-mt-1",attrs:{"type":"onlineCode","title":_vm.$t(
                                        'components.formAuthLogin.help.onlineCode.title'
                                    ),"button":_vm.$t(
                                        'components.formAuthLogin.help.onlineCode.button'
                                    ),"alt":_vm.$t('labels.onlineCode')}})]},proxy:true}],null,true)},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.rgnCode),expression:"rgnCode",modifiers:{"trim":true}}],staticClass:"form-input w-full border-none sm:text-sm sm:leading-5",attrs:{"id":"onlineCode","placeholder":_vm.$t(
                                    'components.formAuthLogin.pattern.onlineCode'
                                ),"type":"text"},domProps:{"value":(_vm.rgnCode)},on:{"input":function($event){if($event.target.composing)return;_vm.rgnCode=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('p',{staticClass:"text-secondary text-sm"},[_c('i18n',{attrs:{"path":"components.formAuthLogin.privacyPolicy"}},[_c('router-link',{staticClass:"text-primary font-bold",attrs:{"to":{ name: 'front.privacyPolicy' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('views.privacyPolicy.title')))])])],1)],1),_c('list-errors',{attrs:{"errors":_vm.errors}}),_c('div',{staticClass:"flex justify-center"},[_c('button-loading',{staticClass:"btn btn-base btn-secondary mr-2",attrs:{"is-loading":_vm.isLoading,"type":"submit"}},[_c('span',[_vm._v(_vm._s(_vm.$t('components.formAuthLogin.submit')))])])],1)],1)]}}],null,false,623240895)})],1),_c('div',{staticClass:"mx-auto w-3/4 text-center text-secondary text-sm"},[_c('p',[_vm._v(_vm._s(_vm.$t('components.formAuthLogin.noInvoice.0')))]),_c('i18n',{attrs:{"path":"components.formAuthLogin.noInvoice.1","tag":"p"}},[_c('router-link',{staticClass:"text-primary hover:underline whitespace-no-wrap",attrs:{"to":{ name: 'front.facilitationPayment' }}},[_vm._v(" "+_vm._s(_vm.$t('components.formAuthLogin.noInvoice.calculator'))+" ")])],1)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }