
import { Component, Prop, Vue } from 'vue-property-decorator';
import TransitionFade from '@/components/util/transition/TransitionFade.vue';

@Component({
    components: { TransitionFade },
})
export default class ListErrors extends Vue {
    @Prop()
    errors!: string[];
}
