
import { Component, Vue } from 'vue-property-decorator';
import DuplicateService from '@/service/invoice/DuplicateService';
import catchHandler from '@/api/catchHandler';
import { namespace } from 'vuex-class';
import IconAction from '@/components/util/IconAction.vue';
import ListErrors from '@/components/util/ListErrors.vue';
import IconFilePdf from '@/components/icon/IconFilePdf.vue';
import IconSpinner from '@/components/icon/IconSpinner.vue';

const AuthUser = namespace('AuthUser');

@Component({
    components: {
        IconSpinner,
        IconFilePdf,
        ListErrors,
        IconAction,
    },
})
export default class DownloadAction extends Vue {
    isLoading = false;
    errors: string[] = [];

    @AuthUser.Getter
    publicRgnNr!: string;

    async download(): Promise<void> {
        this.isLoading = true;

        await DuplicateService.download()
            .then(({ data }) => {
                const link: HTMLAnchorElement = document.createElement('a');
                link.href = `data:application/pdf;charset=UTF-8;base64,${data}`;
                link.download = `RE_ ${this.publicRgnNr}`;
                link.click();
                URL.revokeObjectURL(link.href);

                this.$emit('success');
            })
            .catch(error =>
                catchHandler(
                    error,
                    (errors: string[]) => (this.errors = errors)
                )
            );

        this.isLoading = false;
    }
}
