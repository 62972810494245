
import { Component, Prop, Vue } from 'vue-property-decorator';
import Alert from '@/components/util/alert/Alert.vue';
import IconExclamationTriangle from '@/components/icon/IconExclamationTriangle.vue';

@Component({
    components: { IconExclamationTriangle, Alert },
})
export default class AlertMaintenance extends Vue {
    @Prop({ default: '' })
    text!: string;
}
