var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{class:{
        'bg-black bg-opacity-5 py-8 sm:py-16': _vm.darkMode,
    }},[_c('container',{attrs:{"full-width":_vm.fullWidth}},[_c('div',{staticClass:"mx-auto",class:{
                'md:w-10/12': !_vm.fullWidth,
                'w-full': _vm.fullWidth,
            }},[(_vm.hasTitle || _vm.hasSubTitle)?_c('div',{class:{
                    'sm:pb-8 pb-4': !_vm.small,
                    'text-center': !_vm.noCenterTitle,
                }},[(_vm.hasTitle)?_c('div',{staticClass:"text-secondary text-2xl font-bold uppercase"},[(_vm.title !== '')?[_vm._v(_vm._s(_vm.title))]:_vm._t("title")],2):_vm._e(),(_vm.hasSubTitle)?_c('div',{staticClass:"text-secondary uppercase"},[(_vm.subTitle !== '')?[_vm._v(" "+_vm._s(_vm.subTitle)+" ")]:_vm._t("subTitle")],2):_vm._e()]):_vm._e(),_vm._t("default")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }