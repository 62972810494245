import { AxiosResponse } from 'axios';
import authorizedRequestHeader from '@/api/authorizedRequestHeader';
import api from '@/api';
import AuthUserStore from '@/store/modules/AuthUser';
import store from '@/store';
import { getModule } from 'vuex-module-decorators';

export default {
    async login(rgnNr: string, rgnCode: string): Promise<AxiosResponse> {
        return await api.post('/auth/login', { rgnNr, rgnCode });
    },

    async logout(): Promise<AxiosResponse> {
        return await api.post(
            '/auth/logout',
            {},
            await authorizedRequestHeader()
        );
    },

    async tokenRefresh(): Promise<AxiosResponse> {
        const AuthUser = getModule(AuthUserStore, store);

        return await api.post('auth/refresh', {
            //accessToken: AuthUser.accessToken,
            refreshToken: AuthUser.refreshToken,
        });
    },
};
