
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ListErrors from '@/components/util/ListErrors.vue';
import FormLabel from '@/components/util/form/FormLabel.vue';
import IconExclamationTriangle from '@/components/icon/IconExclamationTriangle.vue';

@Component({
    props: {
        hideLabel: Boolean,
        hideErrorIcon: Boolean,
    },
    components: {
        IconExclamationTriangle,
        FormLabel,
        ListErrors,
        ValidationProvider,
        ValidationObserver,
    },
})
export default class FormInput extends Vue {
    @Prop({ required: true })
    id!: string;

    @Prop()
    label!: string;

    @Prop()
    rules!: object;

    @Prop()
    inputWrapperClass!: string;

    @Prop()
    validationMode: 'aggressive' | 'lazy' | 'passive' | 'eager' = 'lazy';
}
