import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import localeDe from './locales/de.json';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
    return {
        de: localeDe,
    };
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'de',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
    messages: loadLocaleMessages(),
});
