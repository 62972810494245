
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TextareaLimit extends Vue {
    @Prop({ required: true })
    value!: string;

    @Prop({ required: true })
    limit!: number;

    get current(): number {
        return this.value?.length ?? 0;
    }
}
