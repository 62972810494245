
import { Component, Prop, Vue } from 'vue-property-decorator';
import TransitionFade from '@/components/util/transition/TransitionFade.vue';
import IconSpinner from '@/components/icon/IconSpinner.vue';

@Component({
    components: { IconSpinner, TransitionFade },
})
export default class ButtonLoading extends Vue {
    @Prop({ default: false })
    isLoading!: boolean;
}
