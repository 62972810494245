
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconSpinner from '@/components/icon/IconSpinner.vue';

@Component({
    components: { IconSpinner },
})
export default class IsLoading extends Vue {
    @Prop({ required: true })
    isLoading!: boolean;
}
