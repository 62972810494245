import { RouteConfig } from 'vue-router';
import WelcomeView from '@/views/front/WelcomeView.vue';
import { getModule } from 'vuex-module-decorators';
import AuthUserStore from '@/store/modules/AuthUser';
import store from '@/store';

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'front.welcome',
        component: WelcomeView,
        meta: {
            requiresGuest: true,
        },
    },
    {
        path: '/faq',
        name: 'front.faq',
        component: () =>
            import(
                /* webpackChunkName: 'front.faq' */ '@/views/front/FaqView.vue'
            ),
    },
    {
        path: '/kontakt',
        name: 'front.contact',
        component: () =>
            import(
                /* webpackChunkName: 'front.contact' */ '@/views/front/ContactView.vue'
            ),
        beforeEnter(to, _, next) {
            const AuthUser = getModule(AuthUserStore, store);
            // check if user is logged in
            if (AuthUser.isAuthenticated) {
                // redirect to dashboard #login
                next({ name: 'app.dashboard', hash: '#contact' });
                return;
            }

            // check if route to hash is login
            if (to.hash === '#login') {
                // redirect to login if hash is #login
                next({ name: 'app.dashboard', hash: '#login' });
                return;
            }

            next();
        },
    },
    {
        path: '/datenschutz',
        alias: ['/privacyPolicy'],
        name: 'front.privacyPolicy',
        component: () =>
            import(
                /* webpackChunkName: 'front.privacyPolicy' */ '@/views/front/PrivacyPolicyView.vue'
            ),
    },
    {
        path: '/zahlungserleichterung',
        alias: ['/facilitationPayment'],
        name: 'front.facilitationPayment',
        component: () =>
            import(
                /* webpackChunkName: 'front.facilitationPayment' */ '@/views/front/facilitationPayment/CalculateView.vue'
            ),
    },
];

export default routes;
