
import PageSection from '@/components/util/layout/PageSection.vue';
import { Component, Vue } from 'vue-property-decorator';
import InvoiceDuplicateAction from '@/components/views/app/dashboard/sectionActions/InvoiceDuplicateAction.vue';
import FacilitationPaymentAction from '@/components/views/app/dashboard/sectionActions/FacilitationPaymentAction.vue';
import ExtendTermOfPaymentAction from '@/components/views/app/dashboard/sectionActions/ExtendTermOfPaymentAction.vue';

@Component({
    components: {
        ExtendTermOfPaymentAction,
        FacilitationPaymentAction,
        InvoiceDuplicateAction,
        PageSection,
    },
})
export default class SectionActions extends Vue {}
