var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"grid gap-4 grid-cols-1 sm:grid-cols-2"},[_c('form-input',{staticClass:"col-span-1",attrs:{"id":"street","rules":{
            required: _vm.isRequired,
            max: 30,
        },"label":_vm.$t('labels.street')}},[_c('input',{staticClass:"form-input w-full",attrs:{"id":"street","type":"text"},domProps:{"value":_vm.value.street},on:{"input":function($event){return _vm.update('street', $event.target.value)}}})]),_c('div',{staticClass:"grid gap-4 col-span-1 grid-cols-1 sm:grid-cols-5"},[_c('form-input',{staticClass:"col-span-1 sm:col-span-2",attrs:{"id":"zip","rules":{
                required: _vm.isRequired,
                numeric: true,
                max: 5,
            },"label":_vm.$t('labels.zip')}},[_c('input',{staticClass:"form-input w-full",attrs:{"id":"zip","type":"text"},domProps:{"value":_vm.value.zip},on:{"input":function($event){return _vm.update('zip', $event.target.value)}}})]),_c('form-input',{staticClass:"col-span-1 sm:col-span-3",attrs:{"id":"city","rules":{
                required: _vm.isRequired,
                max: 30,
            },"label":_vm.$t('labels.city')}},[_c('input',{staticClass:"form-input w-full",attrs:{"id":"city","type":"text"},domProps:{"value":_vm.value.city},on:{"input":function($event){return _vm.update('city', $event.target.value)}}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }