
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormLabel extends Vue {
    @Prop({ required: true })
    id!: string;

    @Prop({ default: false })
    isRequired!: boolean;
}
