import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import CustomerType from '@/types/CustomerType';
import AddressType from '@/types/AddressType';
import InvoiceType from '@/types/InvoiceType';
import TermOfPaymentType from '@/types/invoice/TermOfPaymentType';
import FacilitationPaymentType from '@/types/invoice/FacilitationPaymentType';
import InvoiceDuplicateType from '@/types/invoice/InvoiceDuplicateType';

@Module({
    name: 'Invoice',
    namespaced: true,
})
export default class Invoice extends VuexModule {
    public rgnNr!: number;

    public kundenforderung!: number;
    public opBetrag!: number;
    public opStatus!: string;
    public opStatusText!: string;
    public rechnungsDatum!: string;
    public datZahlungsziel!: string;

    public invoiceDuplicate?: InvoiceDuplicateType;
    public facilitationPayment?: FacilitationPaymentType;
    public termOfPayment?: TermOfPaymentType;

    public kunde?: CustomerType;
    public empfAdresse?: AddressType;

    get rgnEmpf(): string {
        return `${this.empfAdresse?.adrVorname ?? ''} ${
            this.empfAdresse?.adrName1
        }`.trim();
    }

    get rgnKundenforderung(): number {
        return this.kundenforderung;
    }

    get rgnOpBetrag(): number {
        return this.opBetrag;
    }

    get rgnOpStatusText(): string {
        return this.opStatusText ?? this.opStatus;
    }

    get rgnRechnungsDatum(): string {
        return this.rechnungsDatum;
    }

    get rgnDatZahlungsziel(): string | undefined {
        return this.datZahlungsziel;
    }

    get invoiceDuplicateIsRequested(): boolean | undefined {
        return this.invoiceDuplicate?.isRequested;
    }

    get invoiceDuplicateText(): string | undefined {
        return this.invoiceDuplicate?.text;
    }

    get facilitationPaymentCanCreate(): boolean | undefined {
        return this.facilitationPayment?.canCreate;
    }

    get facilitationPaymentText(): string | undefined {
        return this.facilitationPayment?.text;
    }

    get termOfPaymentDate(): string | undefined {
        return this.termOfPayment?.date;
    }

    get termOfPaymentIsExtended(): boolean | undefined {
        return this.termOfPayment?.isExtended;
    }

    get termOfPaymentCanExtend(): boolean | undefined {
        return this.termOfPayment?.canExtend;
    }

    get termOfPaymentText(): string | undefined {
        return this.termOfPayment?.text;
    }

    @Mutation
    SET_DATA(rechnung: InvoiceType) {
        this.rgnNr = rechnung.rgnNr;

        this.kundenforderung = rechnung.kundenforderung;
        this.opBetrag = rechnung.opBetrag;
        this.opStatus = rechnung.opStatus;
        this.opStatusText = rechnung.opStatusText;
        this.rechnungsDatum = rechnung.rechnungsDatum;
        this.datZahlungsziel = rechnung.datZahlungsziel;

        this.invoiceDuplicate = rechnung.invoiceDuplicate;
        this.facilitationPayment = rechnung.facilitationPayment;
        this.termOfPayment = rechnung.termOfPayment;

        this.kunde = rechnung.kunde;
        this.empfAdresse = rechnung.empfAdresse;
    }

    @Mutation
    SET_INVOICE_DUPLICATE(invoiceDuplicate: InvoiceDuplicateType) {
        this.invoiceDuplicate = invoiceDuplicate;
    }

    @Mutation
    SET_FACILITATION_PAYMENT(facilitationPayment: FacilitationPaymentType) {
        this.facilitationPayment = facilitationPayment;
    }

    @Mutation
    SET_TERM_OF_PAYMENT(termOfPayment: TermOfPaymentType) {
        this.termOfPayment = termOfPayment;
    }

    @Action
    updateInvoiceDuplicate(invoiceDuplicate: { text: string }): void {
        this.SET_INVOICE_DUPLICATE({
            isRequested: true,
            text: invoiceDuplicate.text,
        });
    }

    @Action
    updateFacilitationPayment(facilitationPayment: { text: string }): void {
        this.SET_FACILITATION_PAYMENT({
            canCreate: false,
            text: facilitationPayment.text,
        });
    }

    @Action
    updateTermOfPayment(termOfPayment: { date: string; text: string }): void {
        this.SET_TERM_OF_PAYMENT({
            date: termOfPayment.date || this.termOfPaymentDate || '',
            isExtended: true,
            canExtend: false,
            text: termOfPayment.text,
        });
    }

    @Action
    updateData(rechnung: InvoiceType): void {
        this.SET_DATA(rechnung);
    }
}
