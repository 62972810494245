var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.label !== undefined && !_vm.hideLabel)?_c('form-label',{attrs:{"id":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('validation-provider',{attrs:{"mode":_vm.validationMode,"name":_vm.label,"rules":_vm.rules,"vid":_vm.id},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex flex-grow items-center",class:[
                    {
                        'border bg-white': !!_vm.$slots['help'],
                        'border-primary':
                            !!_vm.$slots['help'] && !errors.length,
                        'border-red-500': !!_vm.$slots['help'] && errors.length,
                        error: errors.length,
                    },
                    _vm.inputWrapperClass,
                ]},[_c('div',{staticClass:"flex-grow self-stretch"},[_vm._t("default")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        !!_vm.$slots['help'] ||
                        (!_vm.hideErrorIcon && errors.length)
                    ),expression:"\n                        !!$slots['help'] ||\n                        (!hideErrorIcon && errors.length)\n                    "}],staticClass:"flex items-center"},[(!!_vm.$slots['help'])?_c('div',{staticClass:"flex mr-2"},[_vm._t("help")],2):_vm._e()])]),(!_vm.hideErrorIcon && errors.length)?_c('icon-exclamation-triangle',{staticClass:"ml-2 text-red-500",attrs:{"width":"w-5","height":"h-5"}}):_vm._e()],1),_c('list-errors',{attrs:{"errors":errors}})]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }