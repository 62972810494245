
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DefaultIcon extends Vue {
    @Prop({ default: 'w-4' })
    width!: string;

    @Prop({ default: 'h-4' })
    height!: string;
}
