
import FormInput from '@/components/util/form/FormInput.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AccountAddressType from '@/types/AccountAddressType';

@Component({
    components: { FormInput },
})
export default class FormAddress extends Vue {
    @Prop({ required: false })
    isRequired!: false;

    @Prop({ required: true })
    value!: AccountAddressType;

    update(key: string, value: unknown): void {
        this.$emit('input', { ...this.value, [key]: value });
    }
}
