
import { Component, Vue } from 'vue-property-decorator';
import Container from '@/components/util/layout/Container.vue';
import { namespace } from 'vuex-class';
import AuthService from '@/service/AuthService';
import catchHandler from '@/api/catchHandler';
import { appType } from '@/helper';

const AuthUser = namespace('AuthUser');

@Component({
    components: { Container },
})
export default class Navigation extends Vue {
    @AuthUser.Getter
    isAuthenticated!: boolean;

    @AuthUser.Action('logout')
    authUserLogout!: () => void;

    get appType(): string {
        return appType();
    }

    get logo(): string {
        return require(`@/assets/img/logo/${this.appType}.svg`);
    }

    get claim(): string {
        return require(`@/assets/img/claim/${this.appType}.svg`);
    }

    get title(): string {
        return `Patienten-Service ${process.env.VERSION}`;
    }

    async logout(): Promise<void> {
        await AuthService.logout()
            .then(() => {
                this.authUserLogout();

                location.href = '/';
            })
            .catch(error => catchHandler(error));
    }
}
