import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import frontRoutes from '@/router/routes/front';
import appRoutes from '@/router/routes/app';
import errorRoutes from '@/router/routes/error';
import store from '@/store';
import { getModule } from 'vuex-module-decorators';
import AuthUserStore from '@/store/modules/AuthUser';
import {
    checkIfAuthIsRequired,
    checkIfGuestIsRequired,
} from '@/helper/VueRouterHelper';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    ...frontRoutes,

    ...appRoutes,

    ...errorRoutes,
];

const router: VueRouter = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return { selector: to.hash };
        }

        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, _from, next) => {
    const AuthUser = getModule(AuthUserStore, store);

    //check if auth is required and if not authenticated
    if (checkIfAuthIsRequired(to) && !AuthUser.isAuthenticated) {
        next({ name: 'front.welcome', hash: '#login' });
        return;
    }

    //check if auth is allowed and if not authenticated
    if (checkIfGuestIsRequired(to) && AuthUser.isAuthenticated) {
        next({ name: 'app.dashboard' });
        return;
    }

    next();
});

export default router;
