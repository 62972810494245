import api from '@/api';
import authorizedRequestHeader from '@/api/authorizedRequestHeader';
import { appType } from '@/helper';

export default {
    async send(
        email: string,
        phone: string,
        message: string,
        files: File[],
        additionalInputs: Map<string, string>
    ) {
        const formData = new FormData();
        formData.append('typ', appType());
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('message', message);

        for (let i = 0; i < files.length; i++) {
            formData.append('files[]', files[i], files[i].name);
        }

        for (const [key, value] of additionalInputs) {
            formData.append(key, value);
        }

        const _authorizedRequestHeader = await authorizedRequestHeader();

        return await api.post('/contact', formData, {
            ..._authorizedRequestHeader,
            timeout: 20000,
        });
    },
};
