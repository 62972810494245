import axios from 'axios';
import router from '@/router';
import { getModule } from 'vuex-module-decorators';
import AuthUserStore from '@/store/modules/AuthUser';
import AuthService from '@/service/AuthService';
import store from '@/store';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_HOST_URL,
    timeout: 5000,
});

api.interceptors.request.use(
    async config => {
        if (process.env.VUE_APP_ENV === 'local') {
            return config;
        }

        await axios
            .get('./ip.php')
            .then(
                ({
                    data: { HTTP_CLIENT_IP, HTTP_X_FORWARDED_FOR, REMOTE_ADDR },
                }) => {
                    if (config === undefined || config.headers === undefined) {
                        return;
                    }

                    config.headers['Client-Ip'] =
                        HTTP_CLIENT_IP ?? HTTP_X_FORWARDED_FOR ?? REMOTE_ADDR;
                }
            )
            .catch(error => console.log(error));

        return config;
    },
    error => Promise.reject(error)
);

api.interceptors.response.use(
    response => response,
    async error => {
        if (error?.response?.status !== 401) {
            return new Promise((_, reject) => {
                reject(error);
            });
        }

        // ausnahme bei login
        if (error?.config?.url === '/auth/login') {
            return new Promise((_, reject) => {
                reject(error);
            });
        }

        const AuthUser = getModule(AuthUserStore, store);
        if (
            error?.config?.url === '/auth/refresh' ||
            error?.response?.message === 'Nicht verfügbar'
        ) {
            AuthUser.logout();

            await router.push({ name: 'login' });

            return new Promise((_, reject) => {
                reject(error);
            });
        }

        // Try request again with new token
        return await AuthService.tokenRefresh()
            .then(({ data: { accessToken, refreshToken } }) => {
                AuthUser.login({ accessToken, refreshToken });

                // new request with new token
                const config = error.config;
                config.headers['Authorization'] = `Bearer ${accessToken}`;

                return new Promise((resolve, reject) => {
                    axios
                        .request(config)
                        .then(response => {
                            resolve(response);
                        })
                        .catch(error => {
                            reject(error);
                        });
                });
            })
            .catch(error => {
                Promise.reject(error);
            });
    }
);

export default api;
