
import { Component, Vue } from 'vue-property-decorator';
import Form from '@/components/form/formContact/Form.vue';
import Modal from '@/components/util/Modal.vue';
import PageSection from '@/components/util/layout/PageSection.vue';
import IconInbox from '@/components/icon/IconInbox.vue';

@Component({
    components: {
        'contact-form': Form,
        IconInbox,
        Modal,
        PageSection,
    },
})
export default class FormContact extends Vue {
    $refs!: {
        modalSuccess: Modal;
    };

    formSend(): void {
        this.$refs.modalSuccess?.open();

        this.$refs.modalSuccess?.closeTimeout();
    }
}
