import { AxiosError } from 'axios';

export default function catchHandler(reason: AxiosError, callback?: Function) {
    const errors: string[] = [];

    if (reason.response === undefined || reason.response === null) {
        let message: string;

        switch (reason.message) {
            case 'Network Error':
                message =
                    'Verbindung zum Server konnte nicht hergestellt werden';
                break;
            default:
                message = reason.message;
                break;
        }

        errors.push(message);

        if (callback !== undefined) {
            callback(errors);
        }

        return;
    }

    if (
        reason.response.status === undefined ||
        reason.response.status === null
    ) {
        console.error(reason.response);

        if (callback !== undefined) {
            callback(errors);
        }

        return;
    }

    if (callback === undefined) {
        return;
    }

    switch (reason.response.status) {
        case 400: {
            Object.values(reason.response.data)?.forEach(value => {
                errors.push((value as Array<string>)?.[0]);
            });
            break;
        }
        case 401:
        default: {
            errors.push(reason.response.data?.message);
            break;
        }
    }

    callback(errors);
}
