
import FormInput from '@/components/util/form/FormInput.vue';
import fileSize from '@/filters/fileSize';
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import IconTimes from '@/components/icon/IconTimes.vue';
import ContactService from '@/service/ContactService';
import catchHandler from '@/api/catchHandler';
import ListErrors from '@/components/util/ListErrors.vue';
import IconInfoCircle from '@/components/icon/IconInfoCircle.vue';
import ButtonLoading from '@/components/util/ButtonLoading.vue';
import FileDropzone from '@/components/util/FileDropzone.vue';
import IconUpload from '@/components/icon/IconUpload.vue';
import TransitionFade from '@/components/util/transition/TransitionFade.vue';
import Tooltip from '@/components/util/Tooltip.vue';
import TextareaLimit from '@/components/util/TextareaLimit.vue';
import { namespace } from 'vuex-class';
import AccountAddressType from '@/types/AccountAddressType';
import FormAddress from '@/components/form/FormAddress.vue';

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

const AuthUser = namespace('AuthUser');

@Component({
    components: {
        FormAddress,
        TextareaLimit,
        Tooltip,
        TransitionFade,
        FileDropzone,
        ButtonLoading,
        IconInfoCircle,
        ListErrors,
        IconTimes,
        IconUpload,
        FormInput,
        ValidationObserver,
        ValidationProvider,
    },
    filters: {
        fileSize,
    },
})
export default class Form extends Vue {
    $refs!: {
        observer: InstanceType<typeof ValidationObserver>;
    };

    @AuthUser.Getter
    isAuthenticated!: boolean;

    isLoading = false;
    errors: string[] = [];

    lastName = '';
    firstName = '';
    address: AccountAddressType = {
        street: '',
        zip: '',
        city: '',
    };

    email = '';
    phone = '';
    message = '';
    files: File[] = [];

    readonly validationRules = {
        text: {
            maxLength: 20000,
        },
        files: {
            size: 15 * 1000000, // 15 * 1MB
            max: 3,
        },
        file: {
            max: 255,
            mimeTypes: ['application/pdf', 'image/*'],
        },
    };
    fileUploadErrors: string[] = [];

    async fileInputOnChange(event: HTMLInputEvent) {
        this.fileUploadErrors = [];

        const files: FileList | null = event.target?.files;
        if (files === null) {
            return;
        }

        this.addFiles(files);
    }

    async onFilesDropped(files: FileList) {
        this.fileUploadErrors = [];

        this.addFiles(files);
    }

    addFiles(files: FileList): void {
        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);

            if (file === null) {
                continue;
            }

            if (file.size === 0) {
                this.fileUploadErrors.push(
                    `Die Datei ${file.name} ist fehlerhaft`
                );

                continue;
            }

            this.addFile(file);
        }
    }

    addFile(file: File): void {
        //check fileName length
        if (file.name.length > this.validationRules.file.max) {
            this.fileUploadErrors.push(
                this.$t('validation.max.string', [
                    `Dateiname von "${file.name}"`,
                    this.validationRules.file.max,
                ]).toString()
            );

            return;
        }

        //check mimeType
        const mimeTypes = this.validationRules.file.mimeTypes.join('|');
        if (file.type.match(mimeTypes) === null) {
            this.fileUploadErrors.push(
                this.$t('validation.mimeType', [file.name]).toString()
            );

            return;
        }

        //check files max
        if (this.files.length >= this.validationRules.files.max) {
            const error = this.$t('validation.max.array', [
                `${this.validationRules.files.max} Dateien`,
            ]).toString();

            if (!this.fileUploadErrors.includes(error)) {
                this.fileUploadErrors.push(error);
            }

            return;
        }

        //check size
        const uploadSize = this.files
            .map(x => x.size)
            .reduce((a, b) => a + b, 0);

        if (uploadSize + file.size > this.validationRules.files.size) {
            this.fileUploadErrors.push(
                this.$t('validation.uploadSize', [
                    this.$options.filters?.fileSize(
                        this.validationRules.files.size
                    ),
                ]).toString()
            );

            return;
        }

        this.files.push(file);
    }

    removeFile(index: number): void {
        this.fileUploadErrors = [];

        const files = this.files;
        this.files = files.filter((x, i) => i !== index);
    }

    async send(): Promise<void> {
        this.isLoading = true;

        this.errors = [];
        this.fileUploadErrors = [];

        const { email, phone, message, files } = this;

        // additionalInputs when not logged in
        const additionalInputs = new Map<string, string>();
        if (!this.isAuthenticated) {
            additionalInputs.set('lastName', this.lastName);
            additionalInputs.set('firstName', this.firstName);
            additionalInputs.set('street', this.address.street);
            additionalInputs.set('zip', this.address.zip);
            additionalInputs.set('city', this.address.city);
        }

        await ContactService.send(
            email,
            phone,
            message,
            files,
            additionalInputs
        )
            .then(() => {
                this.$emit('send');

                this.resetForm();
            })
            .catch(error =>
                catchHandler(
                    error,
                    (errors: string[]) => (this.errors = errors)
                )
            );

        this.isLoading = false;
    }

    resetForm(): void {
        this.firstName = this.lastName = '';
        this.address.street = this.address.zip = this.address.city = '';
        this.email = this.phone = this.message = '';

        this.files = [];

        this.$refs.observer.reset();
    }
}
