var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col antialiased bg-white overflow-hidden",class:[
        _vm.appType,
        {
            'unsupported-browser': _vm.unsupportedBrowser,
            'debug-screens': !_vm.isProduction,
        },
    ],attrs:{"id":"app"}},[_c('div',{staticClass:"space-y-8 sm:space-y-16"},[_c('navigation'),_c('unsupported-browser-warning'),_c('main',[_c('router-view')],1),_c('page-footer')],1),_c('auto-logout')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }