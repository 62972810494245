import api from '@/api';
import authorizedRequestHeader from '@/api/authorizedRequestHeader';
import { AxiosResponse } from 'axios';

export default {
    async invoke(): Promise<AxiosResponse> {
        return await api.post(
            '/invoice/termOfPayment/extend',
            {},
            await authorizedRequestHeader()
        );
    },
};
