<template>
    <page-section full-width>
        <template v-slot:title>
            <div class="text-2xl font-bold uppercase" style="color: #4e9b4d">
                <i18n path="views.dashboard.sections.reviewForest.title"></i18n>
            </div>
        </template>
        <template v-slot:subTitle>
            <div class="uppercase" style="color: #4e9b4d">
                <i18n path="views.dashboard.sections.reviewForest.subTitle" />
            </div>
        </template>
        <is-loading :is-loading="isLoading">
            <div id="rfw"></div>
        </is-loading>
    </page-section>
</template>

<script>
import IsLoading from '@/components/util/IsLoading';
import PageSection from '@/components/util/layout/PageSection';

export default {
    name: 'SectionReviewForest',
    components: { PageSection, IsLoading },
    data: () => ({
        isLoading: true,
    }),
    created() {
        /* eslint-disable */
        (function (w, d, s, o, f, js, fjs) {
            w['Forest-Widget'] = o;
            w[o] =
                w[o] ||
                function () {
                    (w[o].q = w[o].q || []).push(arguments);
                };
            (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
            js.id = o;
            js.src = f;
            js.async = 1;
            fjs.parentNode.insertBefore(js, fjs);
        })(
            window,
            document,
            'script',
            'mw',
            'https://widget-v2.reviewforest.org/scripts.js'
        );
        /* eslint-enable */
    },
    mounted() {
        /* eslint-disable */
        mw('amount', {
            id: '60ec30b0f6adabd6d9e2f04c',
            location: '60ec30b0ce720e4862247b5c',
            autoScroll: true,
            lang: 'de',
        });
        /* eslint-enable */
        setTimeout(() => (this.isLoading = false), 2000);
    },
};
</script>

<style>
#rfw .rfw-main-header {
    position: absolute !important;
    padding: 0 30px 0 !important;
}
</style>
