
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import Card from '@/components/util/card/Card.vue';
import CardBody from '@/components/util/card/CardBody.vue';
import IconTimes from '@/components/icon/IconTimes.vue';
import IsLoading from '@/components/util/IsLoading.vue';
import EventBus from '@/eventBus';

@Component<Modal>({
    components: { IsLoading, IconTimes, CardBody, Card },
    beforeDestroy() {
        this.close();
    },
})
export default class Modal extends Vue {
    @Prop({ required: true })
    title!: string;

    @Prop({ default: 5000 })
    timeout!: number;

    @Prop({ default: false })
    isLoading!: boolean;

    timeoutClose: number | undefined;

    isOpen = false;

    open(): void {
        if (!this.isOpen) {
            this.internalOpen();
        }
    }

    close(): void {
        if (this.isOpen) {
            this.internalClose();
        }
    }

    @Emit('open')
    internalOpen(): void {
        this.isOpen = true;

        EventBus.$emit('modal-open');
    }

    @Emit('close')
    internalClose(): void {
        this.isOpen = false;

        this.clearTimeout();

        EventBus.$emit('modal-close');
    }

    async closeTimeout(timeout: number | null = null): Promise<void> {
        this.timeoutClose = await window.setTimeout(
            () => this.close(),
            timeout ?? this.timeout
        );
    }

    clearTimeout(): void {
        clearTimeout(this.timeoutClose);
    }
}
