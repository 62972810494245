import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/validator';
import '@/assets/less/app.less';
import '@/assets/less/unsupported-browser.less';
import i18n from './i18n';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
