
import IconAction from '@/components/util/IconAction.vue';
import ModalInvoiceDuplicate from '@/components/views/app/dashboard/sectionActions/ModalInvoiceDuplicate.vue';
import { Component, Vue } from 'vue-property-decorator';
import IconFileInvoice from '@/components/icon/IconFileInvoice.vue';

@Component({
    components: {
        IconFileInvoice,
        IconAction,
        ModalInvoiceDuplicate,
    },
})
export default class InvoiceDuplicateAction extends Vue {}
