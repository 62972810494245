
import { Component, Vue } from 'vue-property-decorator';
import PageSection from '@/components/util/layout/PageSection.vue';
import date from '@/filters/date';
import currency from '@/filters/currency';
import { namespace } from 'vuex-class';
import IconInfoCircle from '@/components/icon/IconInfoCircle.vue';
import Modal from '@/components/util/Modal.vue';
import Badge from '@/components/util/badge/Badge.vue';
import BadgeIndicator from '@/components/util/badge/BadgeIndicator.vue';

const Invoice = namespace('Invoice');

@Component({
    components: {
        BadgeIndicator,
        Badge,
        Modal,
        IconInfoCircle,
        PageSection,
    },
    filters: { currency, date },
})
export default class SectionInfo extends Vue {
    @Invoice.Getter
    rgnOpBetrag!: number;

    @Invoice.Getter
    rgnOpStatusText!: string;

    @Invoice.Getter
    rgnRechnungsDatum!: string;
}
