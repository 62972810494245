import { Route } from 'vue-router';

export function checkIfGuestIsRequired(to: Route) {
    return to.matched.some(
        record =>
            record.meta.requiresGuest !== undefined && record.meta.requiresGuest
    );
}

export function checkIfAuthIsRequired(to: Route) {
    return to.matched.some(
        record =>
            record.meta.requiresAuth !== undefined && record.meta.requiresAuth
    );
}
