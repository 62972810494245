
import Navigation from '@/components/layout/Navigation.vue';
import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@/eventBus';
import IconChevronUp from '@/components/icon/IconChevronUp.vue';
import AutoLogout from '@/components/AutoLogout.vue';
import PageFooter from '@/components/layout/PageFooter.vue';
import moment from 'moment';
import { namespace } from 'vuex-class';
import { appType } from '@/helper';
import AlertWarning from '@/components/util/alert/AlertWarning.vue';
import PageSection from '@/components/util/layout/PageSection.vue';
import UnsupportedBrowserWarning from '@/components/UnsupportedBrowserWarning.vue';
import { browserIsUnsupported } from '@/helper/BrowserHelper';

const AuthUser = namespace('AuthUser');

@Component<App>({
    components: {
        UnsupportedBrowserWarning,
        PageSection,
        AlertWarning,
        PageFooter,
        AutoLogout,
        IconChevronUp,
        Navigation,
    },
    mounted() {
        EventBus.$on('modal-open', () => {
            document.body.classList.add('overflow-hidden');
        });
        EventBus.$on('modal-close', () => {
            document.body.classList.remove('overflow-hidden');
        });

        this.logoutIfExpireTokenIsExpired();
    },
})
export default class App extends Vue {
    @AuthUser.Getter
    isAuthenticated!: boolean;

    @AuthUser.Getter
    exp!: number;

    @AuthUser.Action('logout')
    logout!: () => void;

    get appType(): string {
        return appType();
    }

    get isProduction(): boolean {
        return process.env.VUE_APP_ENV === 'production';
    }

    get unsupportedBrowser(): boolean {
        return browserIsUnsupported();
    }

    logoutIfExpireTokenIsExpired(): void {
        if (!this.isAuthenticated) {
            return;
        }

        if (this.exp < moment().unix()) {
            this.logout();

            location.href = '/';
        }
    }
}
