var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"border-b"},[_c('nav',[_c('container',[_c('div',{staticClass:"flex items-center py-2 space-x-8 sm:py-4"},[_c('router-link',{staticClass:"flex-shrink-0",attrs:{"to":"/"}},[_c('img',{staticClass:"block h-10 md:hidden",attrs:{"src":_vm.logo,"alt":"logo","loading":"lazy","title":_vm.title}}),_c('img',{staticClass:"hidden w-auto h-10 md:block",attrs:{"src":_vm.claim,"alt":"claim","loading":"lazy","title":_vm.title}})]),_c('div',{staticClass:"flex flex-grow"},[_c('div',{staticClass:"flex flex-grow items-center justify-between space-x-8 lg:w-1/2 xl:w-3/5"},[_c('router-link',{attrs:{"to":{
                                name: _vm.isAuthenticated
                                    ? 'app.dashboard'
                                    : 'front.welcome',
                            },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [(!isActive)?_c('a',{staticClass:"nav-item",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('components.navigation.home'))+" ")]):_vm._e()]}}])}),_c('div',{staticClass:"space-x-8"},[(!_vm.isAuthenticated)?[_c('router-link',{attrs:{"to":{
                                        name: 'front.welcome',
                                        hash: '#login',
                                    },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [(!isActive)?_c('a',{staticClass:"nav-item",class:{
                                            'router-link-active': isActive,
                                        },attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('buttons.login'))+" ")]):_c('a',{staticClass:"nav-item",attrs:{"href":"#login"}},[_vm._v(" "+_vm._s(_vm.$t('buttons.login'))+" ")])]}}],null,false,3384658797)}),_c('router-link',{staticClass:"nav-item",attrs:{"to":{
                                        name: 'front.welcome',
                                        hash: '#faq',
                                    }}},[_vm._v(" "+_vm._s(_vm.$t('labels.faq'))+" ")])]:[_c('router-link',{staticClass:"nav-item",attrs:{"to":{ name: 'front.faq' }}},[_vm._v(" "+_vm._s(_vm.$t('labels.faq'))+" ")]),_c('button',{staticClass:"nav-item btn btn-sm",attrs:{"type":"button"},on:{"click":_vm.logout}},[_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t( 'components.navigation.logout' ))+" ")])])]],2)],1)]),_c('div',{staticClass:"flex hidden flex-shrink-0 justify-end md:block"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"w-auto h-10",attrs:{"src":_vm.logo,"alt":"logo","loading":"lazy"}})])],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }