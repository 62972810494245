
import { Component, Vue } from 'vue-property-decorator';
import Modal from '@/components/util/Modal.vue';
import IconInfoCircle from '@/components/icon/IconInfoCircle.vue';
import { namespace } from 'vuex-class';

const AuthUser = namespace('AuthUser');

@Component({
    components: { IconInfoCircle, Modal },
})
export default class ModalAutoLogoutInfo extends Vue {
    $refs!: {
        modalInfo: Modal;
    };

    async mounted() {
        if (this.isAuthenticated) {
            return;
        }

        if (!this.showModalAutoLogoutInfo) {
            return;
        }

        this.$refs.modalInfo.open();
    }

    @AuthUser.Getter
    isAuthenticated!: boolean;

    @AuthUser.Getter
    showModalAutoLogoutInfo!: boolean;

    @AuthUser.Action
    setShowModalInfo!: (value: boolean) => void;

    close(): void {
        this.setShowModalInfo(false);

        this.$refs.modalInfo.close();

        this.$router.push({ hash: '#login' });
    }
}
