import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        path: '*',
        name: 'error.not-found',
        component: () =>
            import(
                /* webpackChunkName: 'error' */ '@/views/error/NotFoundView.vue'
            ),
    },
];

export default routes;
