
import Container from '@/components/util/layout/Container.vue';
import IconChevronUp from '@/components/icon/IconChevronUp.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { appType } from '@/helper';

const AuthUser = namespace('AuthUser');

@Component({
    components: { Container, IconChevronUp },
})
export default class PageFooter extends Vue {
    @AuthUser.Getter
    isAuthenticated!: boolean;

    get appType(): string {
        return appType();
    }

    get impressumLink(): string {
        return `https://www.buedingen-${this.appType}.de/impressum/`;
    }

    scrollToTop(): void {
        window.scrollTo(0, 0);
    }
}
