export default function currency(value?: number | null): string {
    if (value === undefined || value === null) {
        return '';
    }

    const output = value
        .toFixed(2)
        .replace('.', ',')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${output} €`;
}
