import { detect } from 'detect-browser';

export function browserIsUnsupported(): boolean {
    const browser = detect();

    switch (browser && browser.name) {
        case 'ie':
        case 'edge':
            return true;
        default:
            return false;
    }
}

export function browserName(): string {
    const browser = detect();

    return browser ? browser.name : '';
}
