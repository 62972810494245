
import IconInfoCircle from '@/components/icon/IconInfoCircle.vue';
import { Component, Vue } from 'vue-property-decorator';
import { createPopper, Instance } from '@popperjs/core';

@Component<Tooltip>({
    components: { IconInfoCircle },

    mounted() {
        const reference = this.$refs.reference;
        const content = this.$refs.content;

        this.$nextTick(() => {
            this.popper = createPopper(reference, content, {
                placement: 'top',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10],
                        },
                    },
                ],
            });
        });
    },
})
export default class Tooltip extends Vue {
    $refs!: {
        reference: Element;
        content: HTMLElement;
    };

    timeoutHide: number | undefined;

    isVisible = false;
    popper: Instance | undefined;

    enter(): void {
        clearTimeout(this.timeoutHide);

        this.isVisible = true;

        this.popper?.update();
    }

    async leave(): Promise<void> {
        this.timeoutHide = await window.setTimeout(
            () => (this.isVisible = false),
            500
        );
    }
}
