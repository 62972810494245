
import { Component, Vue } from 'vue-property-decorator';
import TransitionFade from '@/components/util/transition/TransitionFade.vue';
import IconMinus from '@/components/icon/IconMinus.vue';
import IconTimes from '@/components/icon/IconTimes.vue';
import { namespace } from 'vuex-class';
import Accordion from '@/components/util/Accordion.vue';
import FaqType from '@/types/FaqType';
import dataFaq from '@/data/faq.json';

const AuthUser = namespace('AuthUser');

@Component({
    components: {
        Accordion,
        IconTimes,
        IconMinus,
        TransitionFade,
    },
})
export default class Faq extends Vue {
    $refs!: {
        accordion: Accordion;
    };

    search = '';

    faq: FaqType[] = dataFaq;

    @AuthUser.Getter
    isAuthenticated!: boolean;

    get filteredFaq(): FaqType[] {
        const regExp = this.search
            .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
            .split(' ')
            .map(x => `(?=.*(${x}))`)
            .join('');

        return this.faq.filter(({ answer, question }) => {
            const reg = new RegExp(regExp, 'gi');
            return reg.test(question) || reg.test(answer);
        });
    }

    clearSearch(): void {
        this.search = '';

        this.$refs.accordion.activeIndex = -1;
    }
}
