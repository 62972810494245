import api from '@/api';
import authorizedRequestHeader from '@/api/authorizedRequestHeader';
import { AxiosResponse } from 'axios';

export default {
    async download(): Promise<AxiosResponse> {
        return await api.get(
            '/invoice/duplicate/download',
            await authorizedRequestHeader()
        );
    },

    async request(): Promise<AxiosResponse> {
        return await api.post(
            '/invoice/duplicate/request',
            {},
            await authorizedRequestHeader()
        );
    },
};
