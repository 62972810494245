
import { Component, Vue } from 'vue-property-decorator';
import PageSection from '@/components/util/layout/PageSection.vue';
import AlertWarning from '@/components/util/alert/AlertWarning.vue';
import { browserIsUnsupported, browserName } from '@/helper/BrowserHelper';

@Component<UnsupportedBrowserWarning>({
    components: { AlertWarning, PageSection },
})
export default class UnsupportedBrowserWarning extends Vue {
    get isUnsupported(): boolean {
        return browserIsUnsupported();
    }

    get fullName(): string {
        switch (browserName()) {
            case 'ie':
                return this.$t(
                    'components.unsupportedBrowserWarning.browsers.ie'
                ).toString();
            case 'edge':
                return this.$t(
                    'components.unsupportedBrowserWarning.browsers.edge'
                ).toString();
            default:
                return '';
        }
    }

    get recommendBrowsers(): Array<{ name: string; url: string }> {
        return [
            {
                name: this.$t(
                    'components.unsupportedBrowserWarning.browsers.chrome'
                ).toString(),
                url: 'https://www.google.com/chrome/',
            },
            {
                name: this.$t(
                    'components.unsupportedBrowserWarning.browsers.firefox'
                ).toString(),
                url: 'https://www.mozilla.org/de/firefox/',
            },
            {
                name: this.$t(
                    'components.unsupportedBrowserWarning.browsers.edge-chromium'
                ).toString(),
                url: 'https://www.microsoft.com/de-de/edge',
            },
        ];
    }
}
