
import IconAction from '@/components/util/IconAction.vue';
import ModalExtendTermOfPayment from '@/components/views/app/dashboard/sectionActions/ModalExtendTermOfPayment.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import IconCalendarAlt from '@/components/icon/IconCalendarAlt.vue';
import Badge from '@/components/util/badge/Badge.vue';
import Modal from '@/components/util/Modal.vue';
import IconInfoCircle from '@/components/icon/IconInfoCircle.vue';
import date from '@/filters/date';

const Invoice = namespace('Invoice');

@Component({
    components: {
        IconInfoCircle,
        Modal,
        Badge,
        IconCalendarAlt,
        IconAction,
        ModalExtendTermOfPayment,
    },
    filters: {
        date,
    },
})
export default class ExtendTermOfPaymentAction extends Vue {
    @Invoice.Getter
    termOfPaymentIsExtended!: boolean;

    @Invoice.Getter
    termOfPaymentCanExtend!: boolean;

    @Invoice.Getter
    termOfPaymentText!: string;

    @Invoice.Getter
    rgnDatZahlungsziel!: string;
}
