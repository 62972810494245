var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{ref:"form",staticClass:"space-y-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.send)}}},[(!_vm.isAuthenticated)?_c('div',{staticClass:"grid gap-4 grid-cols-1 sm:grid-cols-2"},[_c('form-input',{attrs:{"id":"lastName","rules":{
                    required: true,
                    max: 100,
                },"label":_vm.$t('labels.lastName')}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.lastName),expression:"lastName",modifiers:{"trim":true}}],staticClass:"form-input w-full sm:text-sm sm:leading-5",attrs:{"id":"lastName","type":"text"},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing)return;_vm.lastName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('form-input',{attrs:{"id":"firstName","rules":{
                    required: true,
                    max: 100,
                },"label":_vm.$t('labels.firstName')}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.firstName),expression:"firstName",modifiers:{"trim":true}}],staticClass:"form-input w-full sm:text-sm sm:leading-5",attrs:{"id":"firstName","type":"text"},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing)return;_vm.firstName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('form-address',{staticClass:"col-span-2",attrs:{"is-required":true},model:{value:(_vm.address),callback:function ($$v) {_vm.address=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"address"}})],1):_vm._e(),_c('div',{staticClass:"grid gap-4 grid-cols-1 sm:grid-cols-2"},[_c('form-input',{attrs:{"id":"email","rules":{
                    required: true,
                    email: true,
                },"label":_vm.$t('labels.email')}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],staticClass:"form-input w-full sm:text-sm sm:leading-5",attrs:{"id":"email","type":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('form-input',{attrs:{"id":"phone","rules":{
                    required: true,
                },"label":_vm.$t('components.formContact.phone.label')},scopedSlots:_vm._u([{key:"help",fn:function(){return [_c('tooltip',[_c('button',{staticClass:"inline-flex hover:text-primary text-secondary focus:outline-none",attrs:{"tabindex":"-1","title":_vm.$t(
                                    'components.formContact.phone.help.title'
                                ),"type":"button"}},[_c('icon-info-circle',{attrs:{"height":"h-5","width":"w-5"}})],1),_c('template',{slot:"content"},[_c('p',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t( 'components.formContact.phone.help.title' ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( 'components.formContact.phone.help.text.0' ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( 'components.formContact.phone.help.text.1' ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( 'components.formContact.phone.help.text.2' ))+" ")])])],2)]},proxy:true}],null,true)},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.phone),expression:"phone",modifiers:{"trim":true}}],staticClass:"form-input w-full border-none sm:text-sm sm:leading-5",attrs:{"id":"phone","type":"text"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.phone=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])],1),_c('form-input',{attrs:{"id":"message","rules":{
                required: true,
                max: _vm.validationRules.text.maxLength,
            },"label":_vm.$t('components.formContact.message'),"input-wrapper-class":"h-48"}},[_c('textarea-limit',{attrs:{"value":_vm.message,"limit":_vm.validationRules.text.maxLength}},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.message),expression:"message",modifiers:{"trim":true}}],staticClass:"form-textarea flex-1 w-full resize-none sm:text-sm sm:leading-5",attrs:{"id":"message"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])],1),_c('transition-fade',[_c('div',[_c('file-dropzone',{directives:[{name:"show",rawName:"v-show",value:(_vm.files.length < _vm.validationRules.files.max),expression:"files.length < validationRules.files.max"}],staticClass:"flex justify-center pb-6 pt-5 px-6 border border-dashed border-secondary",on:{"filesDropped":_vm.onFilesDropped}},[_c('div',{staticClass:"text-center"},[_c('icon-upload',{staticClass:"mx-auto text-gray-400",attrs:{"width":"w-10","height":"h-10"}}),_c('p',{staticClass:"mt-1 text-gray-700 text-sm"},[_c('i18n',{attrs:{"path":"components.formContact.files.info1"}},[_c('label',{staticClass:"text-primary hover:text-secondary focus:underline font-semibold cursor-pointer transition duration-150 ease-in-out"},[_c('input',{staticClass:"hidden",attrs:{"id":"file","accept":_vm.validationRules.file.mimeTypes.join(
                                                ', '
                                            ),"multiple":"","type":"file"},on:{"change":_vm.fileInputOnChange}}),_c('span',{staticClass:"whitespace-no-wrap"},[_vm._v(_vm._s(_vm.$t( 'components.formContact.files.selectFile' )))])])])],1),_c('p',{staticClass:"mt-1 text-gray-600 text-xs"},[_c('i18n',{attrs:{"path":"components.formContact.files.info2"},scopedSlots:_vm._u([{key:"max",fn:function(){return [_vm._v(" "+_vm._s(_vm.validationRules.files.max)+" ")]},proxy:true},{key:"size",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("fileSize")(_vm.validationRules.files.size))+" ")]},proxy:true}],null,true)})],1)],1)]),_c('list-errors',{attrs:{"errors":_vm.fileUploadErrors}})],1)]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.files.length),expression:"files.length"}]},_vm._l((_vm.files),function(file,index){return _c('li',{key:index,staticClass:"inline-flex items-center mr-4 mt-1 text-gray-700 text-sm space-x-2"},[_c('button',{staticClass:"btn btn-xs btn-icon",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeFile(index)}}},[_c('icon-times',{attrs:{"height":"h-4","width":"w-4"}})],1),_c('div',[_vm._v(_vm._s(file.name))]),_c('div',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm._f("fileSize")(file.size)))])])}),0),_c('div',[_c('list-errors',{staticClass:"mb-4",attrs:{"errors":_vm.errors}}),_c('div',{staticClass:"flex items-center justify-end"},[_c('button-loading',{staticClass:"btn btn-base btn-secondary",attrs:{"is-loading":_vm.isLoading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('components.formContact.submit'))+" ")])],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }