import { RouteConfig } from 'vue-router';
import DashboardView from '@/views/app/DashboardView.vue';
import { getModule } from 'vuex-module-decorators';
import FacilitationPaymentStore from '@/store/modules/FacilitationPayment';
import store from '@/store';

const routes: Array<RouteConfig> = [
    {
        path: '/app',
        alias: ['/app/dashboard'],
        name: 'app.dashboard',
        component: DashboardView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/app/zahlungserleichterung',
        alias: ['/app/facilitationPayment'],
        component: () =>
            import(
                /* webpackChunkName: 'app.facilitationPayment' */ '@/views/app/FacilitationPaymentView.vue'
            ),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'app.facilitationPayment.calculate',
                component: () =>
                    import(
                        /* webpackChunkName: 'app.facilitationPayment' */ '@/views/app/facilitationPayment/CalculateView.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'abschliessen',
                alias: ['complete'],
                name: 'app.facilitationPayment.complete',
                component: () =>
                    import(
                        /* webpackChunkName: 'app.facilitationPayment' */ '@/views/app/facilitationPayment/CompleteView.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(_to, _from, next) {
                    const FacilitationPayment = getModule(
                        FacilitationPaymentStore,
                        store
                    );

                    const calculation = FacilitationPayment.getCalculation;

                    if (!calculation) {
                        next({ name: 'app.facilitationPayment.calculate' });
                        return;
                    }

                    next();
                },
            },
        ],
    },
];

export default routes;
