
import { Component, Vue } from 'vue-property-decorator';
import PageSection from '@/components/util/layout/PageSection.vue';
import Page from '@/components/util/layout/Page.vue';
import { namespace } from 'vuex-class';
import SectionFormContact from '@/components/views/app/dashboard/SectionFormContact.vue';
import SectionActions from '@/components/views/app/dashboard/SectionActions.vue';
import InvoiceInfo from '@/components/views/app/InvoiceInfo.vue';
import PageTitle from '@/components/util/layout/PageTitle.vue';
import { getModule } from 'vuex-module-decorators';
import InvoiceStore from '@/store/modules/Invoice';
import store from '@/store';
import InvoiceService from '@/service/InvoiceService';
import SectionInfo from '@/components/views/app/dashboard/SectionInfo.vue';
import SectionReviewForest from '@/components/views/app/dashboard/SectionReviewForest.vue';

const Invoice = namespace('Invoice');

@Component({
    components: {
        SectionReviewForest,
        SectionInfo,
        PageTitle,
        InvoiceInfo,
        SectionActions,
        SectionFormContact,
        Page,
        PageSection,
    },
    beforeRouteEnter(_to, _from, next) {
        InvoiceService.invoke().then(({ data: { invoice } }) => {
            const Invoice = getModule(InvoiceStore, store);

            Invoice.updateData(invoice);

            next();
        });
    },
})
export default class DashboardView extends Vue {
    @Invoice.Getter
    rgnEmpf!: string;
}
