export function appType(): string {
    switch (process.env.VUE_APP_TYPE) {
        case 'dent':
            return 'dent';
        case 'med':
        default:
            return 'med';
    }
}

export function capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * replace all whitespaces
 */
export function trimAll(value: string): string {
    return value.replace(/\s/g, '');
}
