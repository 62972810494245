import moment from 'moment';
import 'moment/locale/de';

export class MaintenanceHelper {
    public shouldShow(): boolean {
        return process.env.VUE_APP_MAINTENANCE_MODE === 'true';
    }

    public isActive(): boolean {
        if (!this.shouldShow()) {
            return false;
        }

        const { date } = this.readEnv();

        if (typeof date === 'string') {
            return moment().isSame(moment(date), 'day');
        }

        const { from, to } = date;
        const mFrom = moment.min([moment(from), moment(to)]);
        const mTo = moment.max([moment(from), moment(to)]);

        return moment().isBetween(mFrom, mTo);
    }

    public getText(): string {
        if (!this.shouldShow()) {
            return '';
        }

        const { date } = this.readEnv();

        if (typeof date === 'string') {
            return (
                moment(date).format('dddd') +
                ', den ' +
                moment(date).format('DD.MM.YYYY')
            );
        }

        const { from, to } = date;
        const mFrom = moment.min([moment(from), moment(to)]);
        const mTo = moment.max([moment(from), moment(to)]);

        const fromDayOfWeek = mFrom.format('dddd');
        const fromDate = mFrom.format('DD.MM.YYYY');
        const fromTime = mFrom.format('HH:mm');
        const toDayOfWeek = mTo.format('dddd');
        const toDate = mTo.format('DD.MM.YYYY');
        const toTime = mTo.format('HH:mm');

        return mFrom.isSame(mTo, 'day')
            ? `${fromDayOfWeek}, den ${fromDate} von ${fromTime} Uhr bis ${toTime} Uhr`
            : `${fromDayOfWeek}, den ${fromDate} von ${fromTime} Uhr bis ${toDayOfWeek}, den ${toDate} ${toTime} Uhr`;
    }

    public readEnv(): { date: string | { from: string; to: string } } {
        return JSON.parse(process.env.VUE_APP_MAINTENANCE_MODE_DATE ?? '');
    }
}

export default new MaintenanceHelper();
