
import Modal from '@/components/util/Modal.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import date from '@/filters/date';
import { namespace } from 'vuex-class';
import TermOfPaymentService from '@/service/invoice/TermOfPaymentService';
import catchHandler from '@/api/catchHandler';
import ListErrors from '@/components/util/ListErrors.vue';
import IconCalendarAlt from '@/components/icon/IconCalendarAlt.vue';
import ButtonLoading from '@/components/util/ButtonLoading.vue';

const Invoice = namespace('Invoice');

@Component({
    components: {
        ButtonLoading,
        IconCalendarAlt,
        ListErrors,
        Modal,
    },
    filters: {
        date,
    },
})
export default class ModalExtendTermOfPayment extends Vue {
    @Prop({ required: true })
    displayButton!: boolean;

    isLoading = false;
    errors: string[] = [];

    duration = 0;

    @Invoice.Getter
    rgnDatZahlungsziel!: string;

    @Invoice.Getter
    termOfPaymentDate!: string;

    @Invoice.Action
    updateTermOfPayment!: (termOfPayment: { text: string }) => void;

    async extendTermOfPayment(callback: Function): Promise<void> {
        this.isLoading = true;
        this.errors = [];

        await TermOfPaymentService.invoke()
            .then(({ data: { duration, text } }) => {
                this.duration = duration;

                this.updateTermOfPayment({ text });

                callback();
            })
            .catch(error =>
                catchHandler(
                    error,
                    (errors: string[]) => (this.errors = errors)
                )
            );

        this.isLoading = false;
    }
}
