import { extend, localize } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';

import {
    confirmed,
    email,
    ext,
    max,
    max_value as maxValue,
    min_value as minValue,
    numeric,
    oneOf,
    regex,
    required,
    required_if as requiredIf,
    size,
} from 'vee-validate/dist/rules';

extend('confirmed', confirmed);
extend('email', email);
extend('ext', ext);
extend('max', max);
extend('max_value', maxValue);
extend('min_value', minValue);
extend('numeric', numeric);
extend('oneOf', oneOf);
extend('regex', regex);
extend('required', required);
extend('required_if', requiredIf);
extend('size', size);

localize('de', de);
