
import { Component, Vue } from 'vue-property-decorator';
import Card from '@/components/util/card/Card.vue';
import CardBody from '@/components/util/card/CardBody.vue';
import Modal from '@/components/util/Modal.vue';
import { ValidationObserver } from 'vee-validate';
import ListErrors from '@/components/util/ListErrors.vue';
import FormInput from '@/components/util/form/FormInput.vue';
import AuthService from '@/service/AuthService';
import { namespace } from 'vuex-class';
import catchHandler from '@/api/catchHandler';
import IconInfoCircle from '@/components/icon/IconInfoCircle.vue';
import IconFileInvoice from '@/components/icon/IconFileInvoice.vue';
import ButtonLoading from '@/components/util/ButtonLoading.vue';
import { getModule } from 'vuex-module-decorators';
import InvoiceStore from '@/store/modules/Invoice';
import store from '@/store';
import { appType } from '@/helper';
import TokenPairType from '@/types/TokenPairType';
import ModalHelp from '@/components/form/formAuth/ModalHelp.vue';
import Alert from '@/components/util/alert/Alert.vue';
import AlertMaintenance from '@/components/AlertMaintenance.vue';
import MaintenanceService from '@/service/MaintenanceService';
import MaintenanceType from '@/types/MaintenanceType';
import { MaintenanceHelper } from '@/helper/MaintenanceHelper';

getModule(InvoiceStore, store);

const AuthUser = namespace('AuthUser');

@Component({
    components: {
        AlertMaintenance,
        Alert,
        ModalHelp,
        ButtonLoading,
        IconFileInvoice,
        IconInfoCircle,
        FormInput,
        ListErrors,
        Modal,
        CardBody,
        Card,
        ValidationObserver,
    },
})
export default class FormAuthLogin extends Vue {
    isLoading = false;
    errors: string[] = [];

    publicRgnNr = '';
    rgnCode = '';

    maintenance: MaintenanceType | null = null;

    @AuthUser.Action('login')
    authUserLogin!: (tokenPair: TokenPairType) => void;

    async mounted() {
        const maintenanceHelper = new MaintenanceHelper();

        if (maintenanceHelper.shouldShow()) {
            this.maintenance = {
                show: maintenanceHelper.shouldShow(),
                active: maintenanceHelper.isActive(),
                text: maintenanceHelper.getText(),
            };

            return;
        }

        await MaintenanceService.invoke().then(result => {
            this.maintenance = result?.data as MaintenanceType;
        });
    }

    get maintenanceShow(): boolean {
        return this.maintenance?.show ?? false;
    }

    get maintenanceActive(): boolean {
        return this.maintenance?.active ?? false;
    }

    get maintenanceText(): string {
        return this.maintenance?.text ? 'am ' + this.maintenance?.text : '';
    }

    get appType(): string {
        return appType();
    }

    async login(): Promise<void> {
        this.isLoading = true;
        this.errors = [];

        const { publicRgnNr, rgnCode } = this;

        await AuthService.login(publicRgnNr, rgnCode)
            .then(({ data: { accessToken, refreshToken } }) => {
                this.authUserLogin({ accessToken, refreshToken });

                this.$router.push({ name: 'app.dashboard' });
            })
            .catch(error =>
                catchHandler(
                    error,
                    (errors: string[]) => (this.errors = errors)
                )
            );

        this.isLoading = false;
    }
}
