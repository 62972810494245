import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import AuthUser from '@/store/modules/AuthUser';
import Invoice from '@/store/modules/Invoice';
import FacilitationPayment from '@/store/modules/FacilitationPayment';
import { RootState } from '@/store/types';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

Vue.use(Vuex);

const ls = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: 'test',
});

const store: StoreOptions<RootState> = {
    strict: true,
    modules: {
        AuthUser: AuthUser,
        Invoice: Invoice,
        FacilitationPayment: FacilitationPayment,
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key),
            },
        }),
    ],
};

export default new Vuex.Store(store);
