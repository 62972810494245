
import IconFileInvoice from '@/components/icon/IconFileInvoice.vue';
import IconInfoCircle from '@/components/icon/IconInfoCircle.vue';
import Modal from '@/components/util/Modal.vue';
import { browserIsUnsupported } from '@/helper/BrowserHelper';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { appType } from '@/helper';

@Component({
    components: { IconFileInvoice, IconInfoCircle, Modal },
})
export default class ModalHelp extends Vue {
    @Prop({ required: true })
    type!: 'rgnNr' | 'onlineCode';

    @Prop({ required: true })
    title!: string;

    @Prop({ required: true })
    button!: string;

    @Prop({ required: true })
    alt!: string;

    get appType(): string {
        return appType();
    }

    get image(): string {
        const extension = browserIsUnsupported() ? 'jpg' : 'webp';

        return require(`@/assets/img/invoice/${this.type}/${this.appType}.${extension}`);
    }
}
