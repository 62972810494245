import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import AccessTokenType from '@/types/AccessTokenType';
import jwtDecode from 'jwt-decode';
import TokenPairType from '@/types/TokenPairType';
import SecureLS from 'secure-ls';

@Module({
    name: 'AuthUser',
    namespaced: true,
})
export default class AuthUser extends VuexModule {
    _kunNr?: number = undefined;
    _rgnNr?: number = undefined;

    _accessToken?: string = undefined;
    _iat?: number = undefined;
    _exp?: number = undefined;

    _refreshToken?: string = undefined;

    _showModalAutoLogoutInfo = false;

    get isAuthenticated(): boolean {
        return !!this._kunNr && !!this._rgnNr && !!this._accessToken;
    }

    get accessToken(): string | undefined {
        return this._accessToken;
    }

    get iat(): number {
        return this._iat ?? 0;
    }

    get exp(): number {
        return this._exp ?? 0;
    }

    get refreshToken(): string | undefined {
        return this._refreshToken;
    }

    get showModalAutoLogoutInfo(): boolean {
        return this._showModalAutoLogoutInfo;
    }

    get publicRgnNr(): string {
        const kunNr = this._kunNr;
        const rgnNr = this._rgnNr;

        if (kunNr && rgnNr) {
            return `${kunNr}/${rgnNr.toString().padStart(6, '0')}`;
        }

        return '';
    }

    @Mutation
    SET_SHOW_MODAL_INFO(value: boolean) {
        this._showModalAutoLogoutInfo = value;
    }

    @Mutation
    SET_AUTH_USER({ accessToken, refreshToken }: TokenPairType) {
        const { kunNr, rgnNr, iat, exp }: AccessTokenType =
            jwtDecode(accessToken);

        this._kunNr = kunNr;
        this._rgnNr = rgnNr;

        this._accessToken = accessToken;
        this._iat = iat;
        this._exp = exp;

        this._refreshToken = refreshToken;
    }

    @Mutation
    CLEAR_AUTH_USER() {
        this._kunNr = undefined;
        this._rgnNr = undefined;

        this._accessToken = undefined;
        this._iat = undefined;
        this._exp = undefined;

        this._refreshToken = undefined;
    }

    @Action
    setShowModalInfo(value: boolean): void {
        this.SET_SHOW_MODAL_INFO(value);
    }

    @Action
    login({ accessToken, refreshToken }: TokenPairType): void {
        this.SET_AUTH_USER({ accessToken, refreshToken });
    }

    @Action
    logout(): void {
        this.CLEAR_AUTH_USER();

        const ls = new SecureLS({
            encodingType: 'aes',
            encryptionSecret: 'test',
        });

        ls.remove('vuex');
    }
}
