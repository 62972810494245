
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import date from '@/filters/date';
import currency from '@/filters/currency';

const AuthUser = namespace('AuthUser');
const Invoice = namespace('Invoice');

@Component({
    filters: { currency, date },
})
export default class InvoiceInfo extends Vue {
    @AuthUser.Getter
    publicRgnNr!: string;

    @Invoice.Getter
    rgnKundenforderung!: number;

    @Invoice.Getter
    rgnRechnungsDatum!: string;
}
