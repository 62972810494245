
import { Component, Vue } from 'vue-property-decorator';
import { VNode } from 'vue';

@Component<Accordion>({
    render(): VNode {
        return this.$scopedSlots.default?.({
            activeIndex: this.activeIndex,

            toggleEvent: (index: number) => ({
                click: () => {
                    this.toggle(index);
                },
            }),
        }) as never;
    },
})
export default class Accordion extends Vue {
    activeIndex = -1;

    toggle(index: number): void {
        const oldIndex = this.activeIndex;

        this.activeIndex = -1;

        if (oldIndex === index) {
            return;
        }

        setTimeout(() => (this.activeIndex = index), 200);
    }
}
