
import { Component, Vue } from 'vue-property-decorator';
import TransitionFade from '@/components/util/transition/TransitionFade.vue';
import moment from 'moment';
import { namespace } from 'vuex-class';
import AuthService from '@/service/AuthService';
import catchHandler from '@/api/catchHandler';
import AlertWarning from '@/components/util/alert/AlertWarning.vue';
import Modal from '@/components/util/Modal.vue';
import IconInfoCircle from '@/components/icon/IconInfoCircle.vue';
import ModalAutoLogoutInfo from '@/components/views/front/home/ModalAutoLogoutInfo.vue';

const AuthUser = namespace('AuthUser');

@Component<AutoLogout>({
    components: {
        ModalAutoLogoutInfo,
        IconInfoCircle,
        Modal,
        AlertWarning,
        TransitionFade,
    },

    watch: {
        $route() {
            if (this.isAuthenticated) {
                this.resetTimers();
            }
        },
    },
})
export default class AutoLogout extends Vue {
    readonly timeout = 1000 * 60 * 10;

    timeoutMessage: number | undefined;
    timeoutLogout: number | undefined;
    intervalTimer: number | undefined;

    isVisible = false;
    timeString = '';

    @AuthUser.Getter
    isAuthenticated!: boolean;

    @AuthUser.Action('logout')
    authUserLogout!: () => void;

    @AuthUser.Action
    setShowModalInfo!: (value: boolean) => void;

    get text() {
        return this.timeString.length
            ? this.$tc('components.autoLogout.text', 2, [this.timeString])
            : this.$tc('components.autoLogout.text');
    }

    async mounted() {
        await this.resetTimers();

        ['click', 'mousemove', 'mousedown', 'scroll', 'keypress'].forEach(
            event => {
                window.addEventListener(event, this.resetTimers);
            }
        );
    }

    async setTimers(): Promise<void> {
        if (!this.isAuthenticated) {
            return;
        }

        this.timeoutMessage = await window.setTimeout(
            () => (this.isVisible = true),
            this.timeout / 2
        );

        this.timeoutLogout = await window.setTimeout(
            () => this.logout(),
            this.timeout
        );

        const endDate = moment()
            .add(this.timeout / 1000, 's')
            .format('YYYY-MM-DD HH:mm:ss');

        this.intervalTimer = await window.setInterval(
            () => (this.timeString = this.generateTimeString(endDate)),
            1000
        );
    }

    async resetTimers(): Promise<void> {
        if (!this.isAuthenticated) {
            return;
        }

        this.isVisible = false;

        await clearTimeout(this.timeoutMessage);

        await clearTimeout(this.timeoutLogout);

        await clearInterval(this.intervalTimer);

        await this.setTimers();
    }

    async logout(): Promise<void> {
        if (!this.isAuthenticated) {
            return;
        }

        this.setShowModalInfo(true);

        await AuthService.logout()
            .then(() => {
                this.authUserLogout();

                location.href = '/';
            })
            .catch(error => catchHandler(error));
    }

    generateTimeString(endDate: string): string {
        const start = moment();
        const end = moment(endDate, 'YYYY-MM-DD HH:mm:ss');
        const duration = moment.duration(end.diff(start));

        const minutes = duration.minutes();
        const seconds = duration.seconds();

        const elements = [];

        if (minutes >= 1) {
            elements.push(this.$tc('components.autoLogout.minutes', minutes));
        }
        if (seconds >= 1) {
            elements.push(this.$tc('components.autoLogout.seconds', seconds));
        }

        return elements.join(', ');
    }
}
