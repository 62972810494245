
import Container from '@/components/util/layout/Container.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    props: {
        fullWidth: Boolean,
        darkMode: Boolean,
        small: Boolean,
        noCenterTitle: Boolean,
    },
    components: {
        Container,
    },
})
export default class PageSection extends Vue {
    @Prop({ default: '' })
    title!: string;

    @Prop({ default: '' })
    subTitle!: string;

    get hasTitle(): boolean {
        return !!this.title || !!this.$slots.title;
    }

    get hasSubTitle(): boolean {
        return !!this.subTitle || !!this.$slots.subTitle;
    }
}
