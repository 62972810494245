import moment from 'moment';

export default function date(
    value?: string | null,
    input = 'YYYY-MM-DD',
    output = 'DD.MM.YYYY'
): string {
    if (value === undefined || value === null) {
        return '';
    }

    return moment(value, input).format(output);
}
