import { AxiosRequestConfig } from 'axios';
import { getModule } from 'vuex-module-decorators';
import AuthUserStore from '@/store/modules/AuthUser';
import store from '@/store';

export default async function authorizedRequestHeader(): Promise<AxiosRequestConfig> {
    const AuthUser = getModule(AuthUserStore, store);

    return {
        headers: {
            Authorization: `Bearer ${AuthUser.accessToken}`,
        },
    };
}
