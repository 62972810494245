
import { Component, Vue } from 'vue-property-decorator';
import DuplicateService from '@/service/invoice/DuplicateService';
import catchHandler from '@/api/catchHandler';
import { namespace } from 'vuex-class';
import IconAction from '@/components/util/IconAction.vue';
import ListErrors from '@/components/util/ListErrors.vue';
import IconEnvelope from '@/components/icon/IconEnvelope.vue';
import IconSpinner from '@/components/icon/IconSpinner.vue';

const Invoice = namespace('Invoice');

@Component({
    components: {
        IconSpinner,
        IconEnvelope,
        ListErrors,
        IconAction,
    },
})
export default class RequestAction extends Vue {
    isLoading = false;
    errors: string[] = [];

    @Invoice.Getter
    invoiceDuplicateIsRequested!: boolean;

    @Invoice.Getter
    invoiceDuplicateText!: string;

    @Invoice.Action
    updateInvoiceDuplicate!: (invoiceDuplicate: { text: string }) => void;

    async request(): Promise<void> {
        this.isLoading = true;

        await DuplicateService.request()
            .then(({ data: { text } }) => {
                this.updateInvoiceDuplicate({ text: text ?? '' });

                this.$emit('success');
            })
            .catch(error =>
                catchHandler(
                    error,
                    (errors: string[]) => (this.errors = errors)
                )
            );

        this.isLoading = false;
    }
}
