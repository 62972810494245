
import IconAction from '@/components/util/IconAction.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import IconFeather from '@/components/icon/IconFeather.vue';

const Invoice = namespace('Invoice');

@Component({
    components: {
        IconFeather,
        IconAction,
    },
})
export default class FacilitationPaymentAction extends Vue {
    @Invoice.Getter
    facilitationPaymentCanCreate!: boolean;

    @Invoice.Getter
    facilitationPaymentText!: string;
}
