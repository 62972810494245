
import Modal from '@/components/util/Modal.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import IconFileInvoice from '@/components/icon/IconFileInvoice.vue';
import DownloadAction from '@/components/views/app/dashboard/sectionActions/modalInvoiceDuplicate/DownloadAction.vue';
import RequestAction from '@/components/views/app/dashboard/sectionActions/modalInvoiceDuplicate/RequestAction.vue';

const AuthUser = namespace('AuthUser');

@Component({
    components: {
        RequestAction,
        DownloadAction,
        IconFileInvoice,
        Modal,
    },
})
export default class ModalInvoiceDuplicate extends Vue {
    $refs!: {
        modal: Modal;
    };

    errors: string[] = [];

    step = 1;

    @AuthUser.Getter
    publicRgnNr!: string;

    get title(): string {
        switch (this.step) {
            case 2:
                return 'Ihr Duplikat ist auf dem Weg';
            default:
                return 'Wie dürfen Wir Ihnen Ihr Rechnungsduplikat zur Verfügung stellen';
        }
    }

    downloaded(): void {
        this.$refs.modal?.close();
    }

    requested(): void {
        this.step = 2;

        this.$refs.modal?.closeTimeout();
    }
}
