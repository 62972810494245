
import PageSection from '@/components/util/layout/PageSection.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: { PageSection },
})
export default class PageTitle extends Vue {
    @Prop({ default: '' })
    title!: string;

    @Prop({ default: '' })
    titleClass!: string;

    @Prop({ default: '' })
    subTitle!: string;
}
