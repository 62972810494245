import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import CalculationType from '@/types/facilitationPayment/CalculationType';

@Module({
    name: 'FacilitationPayment',
    namespaced: true,
})
export default class FacilitationPayment extends VuexModule {
    public maxMonths!: number | null;
    public interestRates?: number[];

    public calculation?: CalculationType;

    public blockedIbanList?: string[];
    public blockedBicList?: number[];

    get getMaxMonths(): number | null {
        return this.maxMonths;
    }

    get getInterestRates(): number[] {
        return this.interestRates ?? [];
    }

    get getCalculation(): CalculationType | null {
        return this.calculation ?? null;
    }

    get getBlockedIbanList(): string[] {
        return this.blockedIbanList ?? [];
    }

    get getBlockedBicList(): number[] {
        return this.blockedBicList ?? [];
    }

    @Mutation
    SET_MAX_MONTHS(maxMonate: number | null) {
        this.maxMonths = maxMonate;
    }

    @Mutation
    SET_INTEREST_RATES(interestRates: number[]) {
        this.interestRates = interestRates;
    }

    @Mutation
    SET_CALCULATION(calculation?: CalculationType) {
        this.calculation = calculation;
    }

    @Mutation
    SET_BLOCKED_IBAN_LIST(blockedIbanList: string[]) {
        this.blockedIbanList = blockedIbanList;
    }

    @Mutation
    SET_BLOCKED_BIC_LIST(blockedBicList: number[]) {
        this.blockedBicList = blockedBicList;
    }

    @Action
    updateMaxMonths(maxMonths: number | null): void {
        return this.SET_MAX_MONTHS(maxMonths);
    }

    @Action
    updateInterestRates(interestRates: number[]): void {
        return this.SET_INTEREST_RATES(interestRates);
    }

    @Action
    updateCalculation(calculation?: CalculationType): void {
        this.SET_CALCULATION(calculation);
    }

    @Action
    updateBlockedIbanList(blockedIbanList: string[]): void {
        return this.SET_BLOCKED_IBAN_LIST(blockedIbanList);
    }

    @Action
    updateBlockedBicList(blockedBicList: number[]): void {
        this.SET_BLOCKED_BIC_LIST(blockedBicList);
    }

    @Action
    reset(): void {
        this.SET_MAX_MONTHS(48);
        this.SET_INTEREST_RATES([]);
        this.SET_CALCULATION(undefined);

        this.SET_BLOCKED_IBAN_LIST([]);
        this.SET_BLOCKED_BIC_LIST([]);
    }
}
