
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BadgeIndicator extends Vue {
    @Prop({ default: 'info' })
    type!: 'info' | 'warning';

    get indicatorClass(): Array<string> {
        switch (this.type) {
            case 'warning': {
                return ['bg-gray-600', 'text-white', 'hover:text-orange-500'];
            }
            case 'info':
            default: {
                return ['bg-primary', 'text-white', 'hover:bg-secondary'];
            }
        }
    }
}
