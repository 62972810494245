
import { Component, Vue } from 'vue-property-decorator';
import FormAuthLogin from '@/components/form/FormAuthLogin.vue';
import PageSection from '@/components/util/layout/PageSection.vue';
import Page from '@/components/util/layout/Page.vue';
import Faq from '@/components/Faq.vue';
import PageTitle from '@/components/util/layout/PageTitle.vue';
import IconFeather from '@/components/icon/IconFeather.vue';
import IconCalendarAlt from '@/components/icon/IconCalendarAlt.vue';
import IconFileInvoice from '@/components/icon/IconFileInvoice.vue';
import IconSearch from '@/components/icon/IconSearch.vue';
import Feature from '@/components/views/front/home/Feature.vue';
import { appType, capitalize } from '@/helper';

@Component({
    components: {
        Feature,
        IconSearch,
        IconFileInvoice,
        IconCalendarAlt,
        IconFeather,
        PageTitle,
        Page,
        Faq,
        PageSection,
        FormAuthLogin,
    },
})
export default class WelcomeView extends Vue {
    get appType(): string {
        return appType();
    }

    get subTitle(): string {
        return this.$t('views.home.subTitle', [
            capitalize(this.appType),
        ]).toString();
    }
}
