
import { Component, Emit, Vue } from 'vue-property-decorator';
import IconUpload from '@/components/icon/IconUpload.vue';

@Component({
    components: { IconUpload },
})
export default class FileDropzone extends Vue {
    dropZoneActive = false;

    @Emit('filesDropped')
    onFilesDropped(event: DragEvent): FileList | undefined {
        return event.dataTransfer?.files;
    }
}
